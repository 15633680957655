/*
  Custom page styles
*/
body {
  overflow-x: hidden;
}

.position-relative {
  position: relative;
}

.shape-triangle {
  padding: 0 !important;
  position: absolute;
  pointer-events: none;
  bottom: 0;
  left: 0;
  right: 0;
  width: 0;
  height: 0;
  margin: auto;
  border-style: solid;
  border-color: transparent transparent #2c6592 transparent;
  border-width: 125px 250px 125px;
  z-index: 8;
}

.shape-shadow {
  padding: 0 !important;
  border-bottom-color: rgba( 0, 0, 0, 0.15 );
  border-width: 130px 260px 130px;
  z-index: 7;
}

.shape-indicator {
  padding: 0 !important;
  text-align: center;
  position: absolute;
  bottom: 0;
  left: 50%;
  margin: auto;
  z-index: 9;
  transform: translate( -50%, -50px );
}

.hero-container {
  width: 100vw;
  height: 100vh;
  position: relative;
}

.hero-image {
  background-size: cover;
}

.home-bg {
  background-image: url('/img/home-bg.jpg');
}

.news-bg {
  background-image: url('/img/news-bg.jpg');
}

.hero-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 6;
}

.shadowed {
  box-shadow: 0px 2px 20px #000;
}

.color-white {
  color: white !important;
}

.color-black {
  color: black !important;
}

.white {
  background: #FFF;
  color: black !important;
}

.black {
  background: #333;
}

.blue {
  background: #00678B;
}

.light-blue {
  background: #a0b6d8;
}

.svg {
   max-width: 100%;
}

.logo-top {
  width: 100px;
  height: 70px;
  position: fixed;
  top: 10px;
  left: 10px;
  z-index: 1000;
}

#header .ui.grid .row, #header .ui.grid .column,
.section-content  .ui.grid .row,
.section-content  .ui.grid .column
{
  padding-left: 0;
  padding-right: 0;
}

.ui.header.section-title {
  font-size: 2.3rem;

  position: absolute;
  top: 80px;
  left: 50%;
  transform:translate(-50%, 0);
  color: #43bdff;
}

@media (max-width:667px) {
  .ui.header.section-title {
    font-size: 1.8rem;
  }

  .shape-triangle {
    padding: 0 !important;
    border-width: 75px 150px 75px;

  }

  .shape-shadow {
    border-width: 80px 160px 80px;
  }

  .shape-indicator {
    transform: translate( -50%, -15px );
  }
}

#mobile-nav {
  overflow: hidden;
}

#navigation-area {
  z-index: 10000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 70px;
}

.paginator-arrow {
  transition: all 200ms ease-in-out;
  color: white;
  cursor: pointer;
}

.paginator-arrow:hover {
  color: blue;
  font-size: 2.3em !important;
}

.section-container{
  width: 100vw;
  position: relative;
  border-top: 2px solid rgba(0, 88, 167, 0.27);
  border-bottom: 2px solid rgba(0, 88, 167, 0.27);
}

.section-content {
  padding-top: 200px;
  padding-bottom: 200px;
}

.footer-content {
  padding-top: 50px;
  padding-bottom: 100px;
}

a {
  color: #a1d7ff;
  transition: color .2s ease-in-out;
}

a:hover {
  color: #00ffec;
}

.perspective {
  perspective: 500px;
}

.effect-3d {
  position: absolute !important;
  right: 0;
  top: 0;
  transform: rotateY( 60deg ) scaleX( 1.25 );
}

.reflection-3d {
  position: absolute !important;
  right: 0;
  top: 0;
  opacity: 0.2;
  transform: translate( -50px, 0 ) rotateX( -50deg ) rotateY( 20deg ) rotateZ( -25deg );
}

.navigation.toc {
  position: fixed;;
  top: 10px;
  right: 10px;
  z-index: 1000;
}

#section1 {
  z-index: 7;
}

#section2 {
  z-index: 8;
  overflow: hidden;
}

#footer {
  z-index: 100;
  color: #91b3b0;
}

#mobile-header {
  margin: 0;
}

#computer-header {
  margin: 0;
}

#mobile-header .header.ui.container {
  margin: 0 !important;
  padding-top: 100px !important;
  padding-bottom: 100px !important;
}

#mobile-header h1 {
  font-size: 1.79rem;
}

.full-width {
  width: 100%;
}

.darked {
  background: rgba( 0, 0, 0 , 0.4 );
}

.circle-border {
  border: 2px solid #fff;
  border-radius: 50%;
}

.parallax {
  background-attachment: fixed;
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
}

.event-card {
  border: ridge 4px;
  height: 100%;
}

.service-card {
  height: 300px;
}

.event-description {
  text-align: center;
  padding: 20px;
  font-size: 2.0rem;
  background: linear-gradient( rgba( 0, 0, 0, 0.5 ) 80%, rgba( 0, 0, 0, 0 ));
}

.event-description p {
  color: white;
}

.service-description {
  font-size: 1.5rem;
}

.custom-rounded {
  transition: all 100ms ease-in !important;
  font-size: 1.6875rem !important;
  background: rgba( 0, 0, 0, 0 ) !important;
  color: white !important;
  border: solid 2px white !important;
  border-radius: 20px !important;
}

.custom-rounded:hover {
  font-size: 1.9rem !important;
  background-color: #0469d2 !important;
}

.worker-card {
  border: 3px solid white;
  padding: 15px;
  margin-right: 15px;
  border-radius: 10px;
}

.news-card {
  padding: 15px;
  border-radius: 5px;
  border: 4px outset white;
}

.news-header {
  height: 350px;
  border: 4px solid white;
}

.article-body {
  font-size: 1.7rem;
}

.article-footer {
  padding: 15px 0px;
}
